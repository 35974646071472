
import Vue from "vue";

import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";
import { authMapActions } from "@/store/modules/auth";
import Password from "@/components/User/Password.vue";
import { logoPath } from "@/helpers/generalHelpers";

interface IData {
  error: string;
  message: string;
  validationErrors: any[];
  loading: boolean;
  credentials: {
    email: string;
    password: string;
  };
  hasResetToken: boolean;
  resetRequestSent: boolean;
  passwordUpdated: boolean;
  showEmailSubmitButton: boolean;
}

export default Vue.extend({
  name: "password-reset",
  components: {
    Password,
    CustomAlert
  },
  data(): IData {
    return {
      error: "",
      message: "",
      validationErrors: [],
      loading: false,
      credentials: {
        email: "",
        password: ""
      },
      hasResetToken: false,
      resetRequestSent: false,
      passwordUpdated: false,
      showEmailSubmitButton: false
    };
  },
  methods: {
    ...authMapActions(["passwordReset", "updatePassword", "canResetPassword"]),
    getLogoPath() {
      return logoPath();
    },
    async resetPasswordAction(): Promise<void> {
      this.loading = true;
      try {
        const { email } = this.credentials;
        const response = await this.passwordReset({ email });
        this.message = response?.data?.message;

        this.resetRequestSent = true;
      } catch (e) {
        this.error = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.loading = false;
      }
    },
    async updatePasswordAction() {
      try {
        this.loading = true;
        const { password } = this.credentials;
        await this.updatePassword({ password });
        this.passwordUpdated = true;
        this.error = "";
      } catch (e) {
        if (e.message.split(";").length > 1) {
          const [nextError] = e.message.split(";");
          this.error = nextError;
        } else {
          if (e.message === "jwt expired") {
            this.error = `Token expired. Please try again.`;
          } else {
            this.error = e.message;
          }
        }
        this.$bugSnagClient.notify(e);
      } finally {
        this.loading = false;
      }
    },
    getPasswordResetToken(): string | (string | null)[] | null {
      return (
        this.$route.query["accessToken"] ||
        localStorage.getItem("password_reset")
      );
    },
    isEmailValid() {
      const fieldEmail = this.$refs.field_email as any;
      if (fieldEmail) {
        this.showEmailSubmitButton = !fieldEmail.$v.$invalid;
      }
      return false;
    }
  },
  created() {
    localStorage.removeItem("access");
    const token = this.getPasswordResetToken() as string;
    if (token) {
      localStorage.setItem("password_reset", token);
      this.canResetPassword()
        .then(() => {
          this.hasResetToken = true;
          this.$router.replace({ query: {} }).catch(() => {});
        })
        .catch(e => {
          this.hasResetToken = false;
          this.$router.replace({ query: {} }).catch(() => {});
          this.error =
            e.message || `Password reset link expired. Request a new one.`;
          localStorage.removeItem("password_reset");
          this.$bugSnagClient.notify(e);
        });
    }
  },
  computed: {
    getPageTitle(): string {
      if (
        !this.resetRequestSent &&
        this.hasResetToken &&
        this.passwordUpdated
      ) {
        return "Password Updated";
      }

      if (!this.resetRequestSent && this.hasResetToken) {
        return this.__getText("global", "updatePasswordHeader");
      }

      return this.__getText("passwordReset", "passwordResetHeader");
    }
  }
});
